import { Navigate, createBrowserRouter } from 'react-router-dom';

import { ROUTES } from './constants/routes';
import { getAcademy } from './features/academy';
import { getFavoriteFolders, getFavorites } from './features/favorites';
import {
  getProductCategories,
  postProductTypeDetails,
} from './features/products';
import { getRecentSearchDemand } from './features/search-demand';
import { getDistributor, getSupplier } from './features/sellers';
import { getPlans } from './features/subscription';
import { AuthLayout } from './layouts/auth';
import { DefaultLayout } from './layouts/default';
import { Root } from './layouts/root';
import { FavoritesType } from './lib/api';
import { queryClient } from './lib/query-client';
import { RootBoundary } from './pages/root-boundary';

// TODO: Add errorElement with sentry
export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <RootBoundary />,
    children: [
      {
        element: <AuthLayout />,
        children: [
          {
            path: '/',
            element: <Navigate to={ROUTES.SIGN_IN} replace={true} />,
          },
          {
            path: ROUTES.SIGN_IN_OLD,
            element: <Navigate to={ROUTES.SIGN_IN} replace={true} />,
          },
          {
            path: ROUTES.SIGN_IN,
            async lazy() {
              const { SignIn } = await import('./pages/sign-in');
              return { Component: SignIn };
            },
          },
          {
            path: ROUTES.SIGN_UP_OLD,
            element: <Navigate to={ROUTES.SIGN_UP} replace={true} />,
          },
          {
            path: ROUTES.SIGN_UP,
            async lazy() {
              const { SignUp } = await import('./pages/sign-up');
              return { Component: SignUp };
            },
          },
          {
            path: ROUTES.INVITE,
            async lazy() {
              const { SignUp } = await import('./pages/sign-up');
              return { Component: SignUp };
            },
          },
          {
            path: ROUTES.FORGOT_PASSWORD,
            async lazy() {
              const { ForgotPassword } = await import(
                './pages/forgot-password'
              );
              return { Component: ForgotPassword };
            },
          },
          {
            path: ROUTES.RESET_PASSWORD,
            async lazy() {
              const { ResetPassword } = await import('./pages/reset-password');
              return { Component: ResetPassword };
            },
          },
        ],
      },
      {
        element: <DefaultLayout />,
        children: [
          {
            path: '/',
            element: <Navigate to={ROUTES.SUPPLIERS} replace={true} />,
          },
          {
            path: ROUTES.DASHBOARD,
            element: <Navigate to={ROUTES.SUPPLIERS} replace={true} />,
          },
          {
            path: ROUTES.ADD_ON_CREDITS_SUCCESS,
            async lazy() {
              const { AddOnCreditsSuccess } = await import(
                './pages/add-on-credits-success'
              );
              return { Component: AddOnCreditsSuccess };
            },
          },
          {
            path: ROUTES.SUPPLIERS,
            async lazy() {
              const { Suppliers } = await import('./pages/suppliers');
              return { Component: Suppliers };
            },
          },
          {
            path: ROUTES.SUPPLIER,
            async lazy() {
              const { Supplier } = await import('./pages/supplier');
              return { Component: Supplier };
            },
            loader: ({ params: { id } }) => {
              const supplierId = Number(id);

              if (isNaN(supplierId)) {
                return null;
              }

              return queryClient.fetchQuery({
                queryKey: ['supplier', supplierId],
                queryFn: () => getSupplier(supplierId),
              });
            },
          },
          {
            path: ROUTES.DISTRIBUTORS,
            async lazy() {
              const { Distributors } = await import('./pages/distributors');
              return { Component: Distributors };
            },
          },
          {
            path: ROUTES.DISTRIBUTOR,
            async lazy() {
              const { Distributor } = await import('./pages/distributor');
              return { Component: Distributor };
            },
            loader: ({ params: { id } }) => {
              const distributorId = Number(id);

              return queryClient.fetchQuery({
                queryKey: ['distributor', distributorId],
                queryFn: () => getDistributor(distributorId),
              });
            },
          },
          {
            path: ROUTES.PRODUCT_CATEGORIES,
            async lazy() {
              const { ProductCategories } = await import(
                './pages/product-categories'
              );
              return { Component: ProductCategories };
            },
            loader: () => {
              return queryClient.fetchQuery({
                queryKey: ['product-categories'],
                queryFn: getProductCategories,
              });
            },
          },
          {
            path: ROUTES.PRODUCT_CATEGORY,
            async lazy() {
              const { ProductCategory } = await import(
                './pages/product-category'
              );
              return { Component: ProductCategory };
            },
            loader: ({ params: { id } }) => {
              const categoryId = Number(id);

              return queryClient.fetchQuery({
                queryKey: ['product-type-details', categoryId],
                queryFn: () => postProductTypeDetails(categoryId),
              });
            },
          },
          {
            path: ROUTES.WEEKLY_PRODUCTS,
            async lazy() {
              const { WeeklyProducts } = await import(
                './pages/weekly-products'
              );
              return { Component: WeeklyProducts };
            },
            loader: () => {
              return queryClient.fetchQuery({
                queryKey: ['academy'],
                queryFn: getAcademy,
              });
            },
          },
          {
            path: ROUTES.SUBSCRIPTION,
            async lazy() {
              const { Subscription } = await import('./pages/subscription');
              return { Component: Subscription };
            },
            loader: () => {
              return queryClient.fetchQuery({
                queryKey: ['plans'],
                queryFn: getPlans,
              });
            },
          },
          {
            path: ROUTES.SUBSCRIPTION_SUCCESS,
            async lazy() {
              const { SubscriptionSuccess } = await import(
                './pages/subscription-success'
              );
              return { Component: SubscriptionSuccess };
            },
          },
          {
            path: ROUTES.FAVORITES,
            element: <Navigate to={ROUTES.FAVORITE_SUPPLIERS} replace={true} />,
          },
          {
            path: ROUTES.FAVORITE_SUPPLIERS,
            async lazy() {
              const { FavoriteSuppliers } = await import(
                './pages/favorite-suppliers'
              );
              return { Component: FavoriteSuppliers };
            },
            loader: () => {
              return [
                queryClient.fetchQuery({
                  queryKey: ['favorites', FavoritesType.SUPPLIER],
                  queryFn: () => getFavorites(FavoritesType.SUPPLIER),
                }),
                queryClient.fetchQuery({
                  queryKey: ['favorite-folders', FavoritesType.SUPPLIER],
                  queryFn: () => getFavoriteFolders(FavoritesType.SUPPLIER),
                }),
              ];
            },
          },
          {
            path: ROUTES.FAVORITE_DISTRIBUTORS,
            async lazy() {
              const { FavoriteDistributors } = await import(
                './pages/favorite-distributors'
              );
              return { Component: FavoriteDistributors };
            },
            loader: () => {
              return [
                queryClient.fetchQuery({
                  queryKey: ['favorites', FavoritesType.DISTRIBUTOR],
                  queryFn: () => getFavorites(FavoritesType.DISTRIBUTOR),
                }),
                queryClient.fetchQuery({
                  queryKey: ['favorite-folders', FavoritesType.DISTRIBUTOR],
                  queryFn: () => getFavoriteFolders(FavoritesType.DISTRIBUTOR),
                }),
              ];
            },
          },
          {
            path: ROUTES.FAVORITE_CRM,
            async lazy() {
              const { FavoriteCRM } = await import('./pages/favorite-crm');
              return { Component: FavoriteCRM };
            },
            loader: () => {
              return [
                queryClient.fetchQuery({
                  queryKey: ['favorites'],
                  queryFn: () => getFavorites(),
                }),
                queryClient.fetchQuery({
                  queryKey: ['favorite-folders'],
                  queryFn: () => getFavoriteFolders(),
                }),
              ];
            },
          },
          {
            path: ROUTES.SEARCH_DEMAND,
            async lazy() {
              const { SearchDemand } = await import('./pages/search-demand');
              return { Component: SearchDemand };
            },
            loader: () => {
              return queryClient.fetchQuery({
                queryKey: ['recent-search-demand'],
                queryFn: () => getRecentSearchDemand(),
              });
            },
          },
        ],
      },
    ],
  },
]);
