import { useQuery } from '@tanstack/react-query';

import { supplierDetails } from '@/lib/api';

export const getSupplier = async (id: number) => {
  const { data } = await supplierDetails(id);
  return data;
};

export const useSupplier = (id?: number) =>
  useQuery({
    queryKey: ['supplier', id],
    queryFn: () => getSupplier(id!),
    enabled: !!id && !isNaN(id),
  });
